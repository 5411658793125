// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$nss-cdc-primary: mat-palette($mat-indigo);
$nss-cdc-accent: mat-palette($mat-pink, A200, A100, A400);
// The warn palette is optional (defaults to red).
$nss-cdc-warn: mat-palette($mat-red);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$nss-cdc-theme: mat-light-theme(( color: ( primary: $nss-cdc-primary, accent: $nss-cdc-accent, warn: $nss-cdc-warn, )));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($nss-cdc-theme);

/* You can add global styles to this file, and also import other style files */


/* Importing Bootstrap SCSS file. */

@import '~bootstrap/scss/bootstrap';
html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}


/* width */

::-webkit-scrollbar {
    width: 10px;
    margin-top: 10px;
}


/* Track */

::-webkit-scrollbar-track {
    background: white;
    border-radius: 10px;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #fa8b04b4;
    border-radius: 10px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #fa8a04;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #555;
}